import React, { useState, useEffect, useRef } from "react";
import { Container, Image, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import axios from "axios";
import { Button } from "react-bootstrap";

import CarouselSlider from "../../../../components/CarouselSlider";
import ClaimCard from "../../../../components/ClaimCard";
import ViewModal from "../../../../components/ViewModal";
import ClaimOpenModalContent from "../../../../components/ClaimOpenModalContent";
import BasicModalContent from "../../../../components/BasicModalContent";
import Tag from "./components/Tag";

import SectionIcon from "../../../../assets/images/announcement.png";

const mapStateToProps = (state) => ({
  nfts: state.nfts,
});

const Details = (claim) => {
  const [showModal, setShowModal] = useState(false);
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [uuid, setUUID] = useState(localStorage.getItem("uuid") || null);
  const [user_token, setUserToken] = useState(
    localStorage.getItem("user_token") || null
  );
  const [claimable, setClaimable] = useState([]);
  const [claimings, setClaimings] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState({});
  const [selectedNFTImage, setSelectedNFTImage] = useState({});
  const [claimQR, setClaimQR] = useState();
  const [showPleaseSignInModal, setPleaseSignInModal] = useState(false);
  const [showClaimedModal, setShowClaimedModal] = useState(false);
  const [showTryAgainModal, setShowTryAgainModal] = useState(false);
  const [showLoadInventory, setShowLoadInventory] = useState(false);
  const [codeExecuted, setCodeExecuted] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [curClaiming, setCurClaiming] = useState("Claiming");
  const [isButton, setIsButton] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [claimSearch, setClaimSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [newNFT, setNewNFT] = useState("");

  const storedNFTs = useSelector((state) => state.nfts);
  useEffect(() => {
    //console.log(claim);
    //console.log(claim.claim.claimWallet);
    const cachedNFTs = localStorage.getItem("nfts");

    if (claim.nfts.length === 0) {
      if (account && cachedNFTs) {
        setClaimings(
          JSON.parse(cachedNFTs).filter(
            (nft) => nft.Taxon === claim.claim.claimFromTaxon
          )
        );
      } else if (!account) {
        setPleaseSignInModal(true);
      } else if (!cachedNFTs) {
        setShowLoadInventory(true);
      }
    } else {
      setClaimings(
        claim.nfts.filter((nft) => nft.Taxon === claim.claim.claimFromTaxon)
      );
    }
  }, []);
  function sortByName(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }
  const fetchData = async () => {
    setIsLoading(true); // Start loading before fetching data
    try {
      const res = await axios.get(`https://www.junction.club:5000/claimed`, {
        headers: {
          account: account,
          taxon: claim.claim.claimFromTaxon,
          issuer_account: claim.claim.claimWallet,
          collection: claim.claim.alias,
          "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
        },
      });

      const sortedClaimable = [...res.data.data].sort(sortByName);
      const sortedClaimings = [...storedNFTs].sort(sortByName);

      const alignedAndFilteredClaimables = sortedClaimings
        .map((item) => {
          const claimStatus = sortedClaimable.find((c) => c.name === item.name);
          if (claimStatus && !claimStatus.claimed) {
            return { ...item, claimed: false };
          }
          return null;
        })
        .filter((item) => item !== null);

      setClaimings(alignedAndFilteredClaimables);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of the result
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const count = (valueBefore, valueAfter) => {
    if (claimings.length <= 6) {
      return valueBefore;
    } else {
      return valueAfter;
    }
  };
  const searchTimeout = useRef(null);

  async function handleSearch() {
    setClaimSearch(null);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    console.log(searchInput);
    const isItClaimed = await axios.get(
      `https://www.junction.club:5000/isClaimed/${searchInput}/${claim.claim.claimWallet}/${claim.claim.alias}`
    );
    if (isItClaimed.data === false) {
      setClaimSearch("Not Claimed");
    } else if (isItClaimed.data === true) {
      setClaimSearch("Claimed");
    } else {
      setClaimSearch("Unknown Number");
    }
    console.log(setClaimSearch.data);
    searchTimeout.current = setTimeout(() => {
      setClaimSearch(null); // Or set to a default value you prefer
    }, 5000);
  }

  function handleInputChange(event) {
    setSearchInput(event.target.value);
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      setClaimSearch("");
      handleSearch();
    }
  }

  const curClaim = async (tid) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    while (!showClaim) {
      setCurClaiming("Claiming.");
      await delay(500);
      setCurClaiming("Claiming..");
      await delay(500);
      setCurClaiming("Claiming...");
      await delay(500);
      const res = await axios.get(`https://www.junction.club:5000/findToken`, {
        headers: {
          tid: tid,
          account: account,
        },
      });
      if (res.data === false) {
        continue;
      } else {
        setShowClaim(true);
        setClaimQR(
          `https://junction-project-images.s3.amazonaws.com/xrpjunkies/droids/${res.data.data.name.replace(
            /^Drowsy Droid #/,
            ""
          )}.png`
        );
        setNewNFT(res.data.data.name);
        //console.log(res.data + " Res Data");
        setCurClaiming(res.data.data.name);
        setIsButton(true);
        break;
      }
    }
  };

  const getClaim = async (tid, collection) => {
    const res = await axios.get(`https://www.junction.club:5000/claim/droid`, {
      headers: {
        tid: tid,
        uuid: uuid,
        collection: collection,
      },
    });
    if (res.data != "Try again") {
      setIsButton(false);
      curClaim(res.data.newToken);
      setClaimQR(res.data.qr);
      //console.log(res.data.qr);
    } else {
      setShowTryAgainModal(true);
    }
  };
  // if (isLoading) {
  //   return <div>Loading...</div>; // Or any other loading indicator you prefer
  // }
  return (
    <div className='claiming-details'>
      <Container fluid className='content'>
        <div className='left-container'>
          <div className='header d-flex align-items-center'>
            <Image src={SectionIcon} alt='Section Icon' />
            <h4 className='title'>Claiming</h4>
          </div>
          <div className='content'>
            <h3>{claim.claim.title}</h3>
            <p className='description'>{claim.claim.requirements}</p>
            <div>
              <Button
                className='primary-button'
                onClick={() => (window.location = "/The_Junkiverse/claiming")}
              >
                Back to claims
              </Button>
            </div>
            <div className='second-section'>
              <div className='search-container'>
                <h4 className='title ms-0 me-4'>CHECK BY ID</h4>
                <InputGroup className='search-bar'>
                  <InputGroup.Text id='basic-addon1'>
                    <FontAwesomeIcon icon={faHashtag} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder='ID'
                    aria-label='search'
                    aria-describedby='search-1'
                    value={searchInput}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    pattern='[0-9]*'
                  />
                </InputGroup>
                <Button
                  variant='primary'
                  className='primary-button'
                  onClick={handleSearch}
                >
                  Check
                </Button>
              </div>

              {claimSearch === "Claimed" ? (
                <Tag className='mt-2' backgroundColor='#191A1A'>
                  Claimed
                </Tag>
              ) : claimSearch === "Not Claimed" ? (
                <Tag className='mt-2'>Unclaimed</Tag>
              ) : claimSearch === "Unkown Number" ? (
                <Tag className='mt-2' backgroundColor='#FC0101'>
                  Unkown Number
                </Tag>
              ) : null}
            </div>
          </div>
        </div>
        <div className='claim-items'>
          {claimings.map((item, index) => {
            console.log(item.name + " " + item.claimed);
            return (
              <ClaimCard
                key={item.NFTokenID}
                claimed={item.claimed}
                tokenID={item.NFTokenID}
                title={item.name}
                image={
                  item.name.includes("Junkie")
                    ? `https://junction-project-images.s3.amazonaws.com/xrpjunkies/images/${item.name.replace(
                        /^Junkie #/,
                        ""
                      )}.png`
                    : item.name.includes("Droid")
                    ? `https://junction-project-images.s3.amazonaws.com/xrpjunkies/droids/${item.name.replace(
                        /^Drowsy Droid #/,
                        ""
                      )}.png`
                    : item.image
                }
                onClickView={() =>
                  setClaimings != 0
                    ? !setClaimings[item.claimed]
                      ? (setShowModal(true),
                        setSelectedNFT(item),
                        setSelectedNFTImage(
                          `https://junction-project-images.s3.amazonaws.com/xrpjunkies/images/${item.name.replace(
                            /^Junkie #/,
                            ""
                          )}.png`
                        ))
                      : (setShowClaimedModal(true),
                        console.log(claim.claim.alias))
                    : null
                }
                buttonText='Claim'
              />
            );
          })}
        </div>
      </Container>

      <ViewModal
        backdrop='static'
        size='md'
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <ClaimOpenModalContent
          loadingTitle={selectedNFT.name}
          button={isButton}
          buttonText='Done'
          image={selectedNFTImage}
          claimQR={claimQR}
          link={() =>
            window.open(
              claimQR.replace("_q.png", ""),
              "popup",
              `width=600, height=800, top=(window.screen.height / 2) - (h / 2), left=(window.screen.width / 2) - (w / 2), location=no`
            )
          }
          openedTitle={curClaiming}
          onClickConfirm={async () =>
            await getClaim(selectedNFT.NFTokenID, claim.claim.alias)
          }
          onClickClose={() => setShowModal(false)}
          onClickButton={() => {
            setShowModal(false);
            window.location.reload();
            setShowClaim(true);
          }}
        />
      </ViewModal>
      <ViewModal
        show={showPleaseSignInModal}
        onHide={() => setPleaseSignInModal(false)}
      >
        <BasicModalContent
          loadingTitle='Sign in to see your Claims'
          buttonText='Close'
          onClickButton={() => {
            window.location = "/The_Junkiverse/claiming";
          }}
        />
      </ViewModal>
      <ViewModal
        show={showClaimedModal}
        onHide={() => setShowClaimedModal(false)}
      >
        <BasicModalContent
          loadingTitle='Already Claimed'
          buttonText='Close'
          onClickButton={() => setShowClaimedModal(false)}
        />
      </ViewModal>
      <ViewModal
        show={showTryAgainModal}
        onHide={() => setShowTryAgainModal(false)}
      >
        <BasicModalContent
          loadingTitle='Something Didnt Work'
          loadingDescription='Please try reload this page and try again'
          buttonText='Reload'
          onClickButton={() => {
            setShowTryAgainModal(false);
            this.forceUpdate();
          }}
        />
      </ViewModal>
      <ViewModal
        show={showLoadInventory}
        onHide={() => setShowLoadInventory(false)}
      >
        <BasicModalContent
          loadingTitle='Please Load Inventory'
          loadingDescription='Inventory has to be loaded in order to claim'
          buttonText='Load'
          onClickButton={() => {
            setShowLoadInventory(false);
            window.location = "/The_Junkiverse/inventory";
          }}
        />
      </ViewModal>
    </div>
  );
};

export default connect(mapStateToProps)(Details);
