import { Button, Container, Image } from "react-bootstrap";
import "./styles.scss";
import { useState } from "react";

import LogoutModal from "../../components/LogoutModal";
import ViewModal from "../../components/ViewModal";


const RefreshOffer = () => {

        
    window.location = "/The_Junkiverse/offers"


  return 
};

export default RefreshOffer;