import { Col, Image, Row, Button } from "react-bootstrap";
import { IpfsImage } from "react-ipfs-image";
import "./styles.scss";

import Section from "../Section";

import StatsIcon from "../../assets/images/stats.png";
import MetaIcon from "../../assets/images/announcement.png";

const ItemDetailModalContent = ({
  tag,
  description,
  stats,
  metadata,
  contents,
  button,
  onConfirm,
  image,
  link,
  linkText,
  onCancel,
}) => {
  const containsIpfs = (image) => {
    return image.includes("ipfs");
  };

  return (
    <div className='item-detail-modal-content'>
      <div className='left-container'>
        <div className='image-container'>
          {containsIpfs(image) ? (
            <IpfsImage
              className='w-full h-full object-contain'
              style={{ maxWidth: "100%" }}
              hash={image.replace("https://cf-ipfs.com/ipfs/", "")}
            />
          ) : (
            <img
              className='w-full h-full object-contain'
              style={{ maxWidth: "100%" }}
              src={image}
            />
          )}
          {tag ? <div className='tag'>{tag}</div> : null}
        </div>
        <div className='lootButtons'>
          {button ? (
            <Button className='primary-button' onClick={onConfirm}>
              Open
            </Button>
          ) : null}
          {button ? (
            <Button className='secondary-button' onClick={onCancel}>
              Cancel
            </Button>
          ) : null}
        </div>
        <div className='item-info'>
          <div className='description-title'>Description:</div>
          <div className='description-text'>
            {description ?? "-"}
            {link ? (
              <a href='#' onClick={link}>
                {linkText}
              </a>
            ) : null}
          </div>
        </div>
      </div>

      <div className='right-container'>
        <div className='sections'>
          {metadata ? (
            <Section
              className='modal-section'
              title='Metadata'
              renderLeft={<Image src={MetaIcon} alt='Meta Icon' />}
            >
              <div className='metadata-items-container'>
                <Row>
                  {metadata.map((item, index) => (
                    <Col xs={6} key={index}>
                      <div className='metadata-item'>
                        <div className='title'>{item.trait_type}</div>
                        <div className='sub-text'>{item.value}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Section>
          ) : null}

          {contents ? (
            <Section
              className='modal-section'
              title='Contents'
              renderLeft={<Image src={MetaIcon} alt='Contents Icon' />}
            >
              <div className='metadata-items-container'>
                <Row>
                  {contents?.map((item, index) => (
                    <Col xs={6} key={index}>
                      <div className='metadata-item'>
                        <div className='title'>{item.trait_type}</div>
                        <div className='sub-text'>{item.value}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Section>
          ) : null}
          {stats ? (
            <Section
              className='modal-section'
              title='Stats'
              renderLeft={<Image src={StatsIcon} alt='Stats Icon' />}
            >
              <div className='stats-items-container'>
                <Row>
                  {stats?.map((item, index) => (
                    <Col xs={4} key={index}>
                      <div className='stats-item'>
                        <div className='title'>Coming</div>
                        <div className='subtitle'>Soon</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Section>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ItemDetailModalContent;
