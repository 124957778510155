import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";

const BasicModalContent = ({
    loadingTitle,
    loadingDescription,
    onClickButton,
    buttonText = "View",
}) => {

    return (
        <div className="item-open-modal">
            <div className="claimed">
                        <h4 className="title">{loadingTitle}</h4>
                        <p className="description">{loadingDescription}</p>
                        <Button className="primary-button" onClick={onClickButton}>{buttonText}</Button>
                    </div>
        </div>
    )
}

export default BasicModalContent;