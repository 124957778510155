import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";
import axios from "axios"
import LogoAnimation from "../../assets/images/logo-animation.gif";

const LoginOpenModalContent = ({
  loadingTitle = "Waiting for signature",
  loadingDescription = "Sign in by scanning QR or opening on mobile",
  openedTitle = "Signed!",
  openedDescription,
    onClickButton,
  getAccountButton,
    buttonText = "Close",
    onMobileText = "Open on Mobile",
    onMobile,
    uuid,
  image
}) => {
    const [loading, setLoading] = useState(true);
    const [resolved, setResolved] = useState(false);
    const [isImage, setIsImage] = useState(image)

    const resolve = async () => {
        if (!resolved) {
            const resolveUuid = await axios.get(`https://www.junction.club:5000/payload`,
                {
                    headers: {
                        "uuid": uuid,
                        "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
                    },
                }            )
            if (resolveUuid.data.data.meta.resolved) {
                setLoading(false)
                setResolved(true)
                setIsImage(LogoAnimation)
            }
            else {
                const delay = ms => new Promise(res => setTimeout(res, ms));
                await delay(5000)
                resolve();
            }
        }
    }
    resolve();

  return (
    <div className="item-open-modal">
          <div className="image-container"><img src={isImage} className="image-container"></img></div>
      {loading
        ? (
                  <div className="claimed">
            <h4 className="title">{loadingTitle}</h4>
                      <p className="description">{loadingDescription}</p>
                      <Button className="primary-button" onClick={onClickButton}>{buttonText}</Button>
                      <Button className="primary-button-two" onClick={onMobile}>{onMobileText}</Button>
                  </div>

        )
        : (
          <div className="claimed">
            <h4 className="title">{openedTitle}</h4>
            {openedDescription
              ? <p className="description">{openedDescription}</p>
              : null
            }
            <Button className="primary-button" onClick={getAccountButton}>{buttonText}</Button>
          </div>
        )}
    </div>
  )
}

export default LoginOpenModalContent;
