import React, { useEffect } from "react";
import { useLoadNFTs } from "../../utils/useLoadNFTs";

const Zed2 = () => {
  const { handleLoadNFTs, nfts, statusMessage } = useLoadNFTs();

  useEffect(() => {
    console.log("Zed2 component mounted.");

    console.log("Effect triggered: handleLoadNFTs called");
    handleLoadNFTs();

    return () => {
      console.log("Zed2 component unmounted.");
    };
  }, [handleLoadNFTs]);

  return (
    <div>
      <p>{statusMessage}</p>
      {nfts.length > 0 && (
        <ul>
          {nfts.map((nft, index) => (
            <li key={index}>
              {nft.name} - {nft.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Zed2;
