import { Image } from "react-bootstrap";
import "./styles.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { TabTitle } from "../../utils/GeneralFunctions";
import { changeMetaDescription } from "../../utils/GeneralFunctions";

import Section from "../../components/Section";
import ClaimCard from "../../components/ClaimCard";
import CarouselSlider from "../../components/CarouselSlider";
import JustQR from "../../components/JustQR";
import SectionIcon from "../../assets/images/announcement.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faHandshake,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import ViewModal from "../../components/ViewModal";
import OfferCard from "../../components/OfferCard";
import { Link } from "react-router-dom";
import ReloadIcon from "../../assets/images/reload.png";

const OffersPage = () => {
  TabTitle("Offers - Junction");
  changeMetaDescription("See all current pending offers.");
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [uuid, setUUID] = useState(localStorage.getItem("uuid") || null);
  const [filters, setFilters] = useState(
    localStorage.getItem("filters") || null
  );
  const [unclaimed, setUnclaimed] = useState([]);
  const [signQR, setSignQR] = useState();
  const [showQR, setShowQR] = useState(false);
  const [mobileLink, setMobileLink] = useState();

  const trustLines = [{ name: "JSC" }, { name: "JUNK" }];
  useEffect(() => {
    const fetchData = async () => {
      console.log("Call");
      const res = await axios.get(
        `https://api.xrpldata.com/api/v1/xls20-nfts/offers/offerdestination/${account}`
      );
      const offers = res.data.data.offers;
      const relevantOffers = [];
      for (let i = 0; i < offers.length; i++) {
        console.log(i);
        const list = JSON.parse(filters);
        console.log(list);
        if (list.some((obj) => Object.values(obj).includes(offers[i].Owner))) {
          const collection = list.find(
            (item) => item.issuer === offers[i].Owner
          );
          const offer = {
            tid: offers[i].NFTokenID,
            offerID: offers[i].OfferID,
            issuer: offers[i].Owner,
            name: collection.name,
          };
          relevantOffers.push(offer);
        }
      }
      console.log(relevantOffers);
      setUnclaimed(relevantOffers);
    };
    fetchData();
  }, []);

  const count = (valueBefore, valueAfter) => {
    if (unclaimed.length <= 6) {
      return valueBefore;
    } else {
      return valueAfter;
    }
  };

  const claim = async (uuid, issuer, tid) => {
    const newQR = await axios.post(
      `https://www.junction.club:5000/claim/easy`,
      {
        tid: tid,
        uuid: uuid,
        issuer: issuer,
      }
    );
    console.log(newQR.data);
    setSignQR(newQR.data);
    setShowQR(true);
  };

  return (
    <div className='offers-page container-lg'>
      <Section
        className='unclaimed-offers'
        title='UNCLAIMED OFFERS'
        renderLeft={
          <FontAwesomeIcon
            icon={faSackDollar}
            size='xl'
            style={{ color: "#ff3400" }}
          />
        }
      >
        <div className='claimings'>
          {unclaimed.map((item, index) => {
            return (
              <ClaimCard
                key={index}
                tokenID={item.tid}
                title={`From: ${item.name}`}
                buttonText='Claim'
                image={
                  "https://junction-site-images.s3.amazonaws.com/Droid_Placeholder.png"
                }
                claimed={"offered"}
                onClickView={() => {
                  claim(uuid, item.issuer, item.tid);
                }}
              />
            );
          })}
        </div>
        <ViewModal
          backdrop='static'
          size='md'
          show={showQR}
          onHide={() => setShowQR(false)}
        >
          <JustQR
            buttonText='Done'
            buttonText2='Open on Mobile'
            claimQR={signQR}
            link={() =>
              window.open(
                signQR.replace("_q.png", ""),
                "popup",
                `width=600, height=800, top=(window.screen.height / 2) - (h / 2), left=(window.screen.width / 2) - (w / 2), location=no`
              )
            }
            onClickClose={() => {
              setShowQR(false);
              window.location = "/The_Junkiverse/offers";
              console.log(signQR);
            }}
          />
        </ViewModal>
      </Section>
    </div>
  );
};

export default OffersPage;
