import { useState } from "react";
import { Button } from "react-bootstrap";
import { IpfsImage } from "react-ipfs-image";
import "./styles.scss";

const ItemOpenModalContent = ({
  loadingTitle = "Opening...",
  loadingDescription,
  openedTitle = "Opened",
  openedDescription,
  onClickButton,
  onClickConfirm,
  onClickClose,
  image,
  qrImage,
  qr,
  afterImage,
  buttonText,
  button,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [showingImage, setShowingImage] = useState(image);

  const containsIpfs = (image) => {
    return image.includes("ipfs");
  };

  if (!confirmed) {
    if (afterImage) {
      setConfirmed(true);
      setShowingImage(afterImage);
    }
    if (qr) {
      setConfirmed(true);
      setShowingImage(qrImage);
    }
  }

  return (
    <div className="item-open-modal">
      {!confirmed ? (
        <>
          <div className="text-container">
            <h4 className="title">{loadingTitle}</h4>
            <div className="image-container">
              {containsIpfs(image) ? (
            <IpfsImage className="img-size" hash={image} />
          ) : (
            <img className="img-size" src={image} />
          )}
            </div>
            <p className="description">{loadingDescription}</p>
          </div>
          {button ? (
            <div>
              <Button
                className="primary-button"
                style={{
                  marginRight: "50px",
                  marginLeft: "50px",
                  marginTop: "20px",
                }}
                onClick={onClickConfirm}
              >
                Confirm
              </Button>
              <Button
                className="primary-button"
                style={{ marginLeft: "50px", marginTop: "20px" }}
                onClick={onClickClose}
              >
                Close
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <div className="text-container claimed">
          {qr ? (
            <div className="image-container">
              <img src={qrImage} />
            </div>
          ) : (
            <div className="image-container">
              <img src={showingImage} />
            </div>
          )}
          <h4 className="title">{openedTitle}</h4>
          {openedDescription ? (
            <p className="description">{openedDescription}</p>
          ) : null}
          <Button className="primary-button" onClick={onClickButton}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ItemOpenModalContent;
