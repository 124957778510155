import React, { useState } from "react";
import "./styles.scss";

function App() {
  const [email, setEmail] = useState("");
  const [Discord, setDiscord] = useState("");
  const [DiscordImg, setDiscordImg] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const nexusKey =
    "235e5e9e6a97fa9634944b83d715b6b8f478861f65f3fbabd71e190ee4e5702d";
  const nexusSecret =
    "ab35f89c2cf893e3c4b7a779b5d54488a3499e1473ee2713b0458b9df8d8a976";

  const handleUsernameChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginApiEndpoint = "https://staging.buildnexus.io/api/user/login";

    try {
      const loginResponse = await fetch(loginApiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //nexusKey: nexusKey,
          //nexusSecret: nexusSecret,
        },
        body: JSON.stringify({ email, password }),
      });

      if (!loginResponse.ok) {
        throw new Error(`HTTP error! status: ${loginResponse.status}`);
      }

      const loginData = await loginResponse.json();
      console.log("Login successful:", loginData);
      const accessToken = loginData.access_token;
      console.log(accessToken);

      // Now use the access token to make another API call
      const infoApiEndpoint = "https://staging.buildnexus.io/api/user/profile";
      const infoResponse = await fetch(infoApiEndpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      });

      if (!infoResponse.ok) {
        throw new Error(`HTTP error! status: ${infoResponse.status}`);
      }

      const infoData = await infoResponse.json();
      console.log("Additional info received:", infoData);
      setUserName(infoData.data.name);
      setDiscord(infoData.data.linked_accounts[0].username);
      if (
        infoData.data.linked_accounts &&
        infoData.data.linked_accounts.length > 0
      ) {
        setDiscordImg(infoData.data.linked_accounts[0].profile_picture); // Assuming 'avatar' is the field with the image URL
      } else {
        console.log("No linked accounts found.");
      }
      console.log(userName);
    } catch (error) {
      console.error("Error making the API call:", error);
    }
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Username:
              <input
                type='text'
                value={email}
                onChange={handleUsernameChange}
              />
            </label>
          </div>
          <div>
            <label>
              Password:
              <input
                type='password'
                value={password}
                onChange={handlePasswordChange}
              />
            </label>
          </div>
          <div>
            <button type='submit'>Test API</button>
          </div>
        </form>
        {userName && <p>Welcome, {userName}!</p>}
        {Discord && <p>Discord, {Discord}!</p>}
        {DiscordImg && <img width='100' src={DiscordImg} alt="User's Avatar" />}
        {/* Display the user's name here */}
      </header>
    </div>
  );
}

export default App;
