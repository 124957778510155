import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";

import Logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <footer>
      <Image className="footer-logo" src={Logo} alt="Logo" />
      <div className="d-flex flex-column align-items-center w-100">
        <ul className="links">
          <Link to="/">ABOUT</Link>
          <Link to="/">Projects</Link>
          <Link to="/">support</Link>
          <Link to="/">build</Link>
          <Link to="/">TERMS & CONDITIONS</Link>
          <Link to="/">PRIVACY POLICY</Link>
        </ul>
        <div className="copyright-text">
          junction.club © 2023 All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
