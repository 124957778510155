import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios"; // Now we'll use axios for the request
import "./styles.scss";
import { TabTitle } from "../../utils/GeneralFunctions";
import StoreCard from "../../components/StoreCard";
import StoreModal from "../../components/StoreModal";

const Store = () => {
  TabTitle("Store - Junction");
  const [showModal, setShowModal] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState(localStorage.getItem("account") || "");
  const [uuid, setUUID] = useState(localStorage.getItem("uuid") || null);

  const mintNFT = async (currency, value, issuer) => {
    setShowModal(true); // Show the modal
    setIsLoading(true);
    setStatusMessage("Minting in progress...");
    try {
      const response = await axios.post(
        "https://www.junction.club:5000/mint-nft",
        {
          uuid,
          account,
          currency,
          value,
          issuer,
          "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
        }
      );
      setStatusMessage("NFT Minted and Offer Sent!");
      console.log("Success:", response.data);
      setIsLoading(false);
      //alert("NFT Minting and Offer Sent!");
    } catch (error) {
      console.error("Error:", error);
      setStatusMessage("Error minting NFT");
      setIsLoading(false);
      //alert("Error minting NFT");
    }
  };

  return (
    <div className='store-page'>
      <StoreCard
        title='Loot Box'
        image={
          "https://junkiverse-nfts.s3.amazonaws.com/loot-crates/CHR1STM4S_CR4T3_V1/crates/NFT/CHR1STM4S_CR4T3_V1.png"
        }
        buttonText='Mint'
        mintNFT={() =>
          mintNFT("JSC", "250", "rJ726mi7cXLfsnuehL84nmjjK14Rmx2ZQG")
        }
        currency='JSC'
        value='250'
      />
      <StoreCard
        title='Loot Box'
        image={
          "https://junkiverse-nfts.s3.amazonaws.com/loot-crates/CHR1STM4S_CR4T3_V1/crates/NFT/CHR1STM4S_CR4T3_V1.png"
        }
        buttonText='Mint'
        mintNFT={() =>
          mintNFT("XRP", "2", "rJ726mi7cXLfsnuehL84nmjjK14Rmx2ZQG")
        }
        currency='XRP'
        value='2'
      />
      <StoreModal
        show={showModal}
        onHide={() => setShowModal(false)}
        message={statusMessage}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Store;
