import CarouselSlider from "../../../../components/CarouselSlider";
import GridCard from "../GridCard";
import "./styles.scss";

const Roadmap = ({ roadmap }) => {
  const isFlexGrid = window.innerWidth <= 1500;

  return (
    <div className="roadmap">
      {isFlexGrid ? (
        <div className="flex-grid">
          {roadmap.map((item, index) => (
            <GridCard
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
            />
          ))}
        </div>
      ) : (
        <CarouselSlider
          className="roadmap-slider"
          slidesToShow={3}
          customSettings={{
            className: "center",
            dots: false,
            infinite: false,
            autoplay: false,
            responsive: [
              {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
        >
          {roadmap.map((item, index) => (
            <GridCard
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
            />
          ))}
        </CarouselSlider>
      )}
    </div>
  );
};


export default Roadmap;
