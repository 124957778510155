import React, { useState, useEffect } from "react";
import { loadNFTs } from "../../utils/loadNFTs"; // Adjust the path as necessary

const Zed = () => {
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [statusMessage, setStatusMessage] = useState("");
  const [nfts, setNfts] = useState([]);

  // Effect to update localStorage when account changes
  useEffect(() => {
    if (account) {
      localStorage.setItem("account", account);
    } else {
      localStorage.removeItem("account");
    }
  }, [account]);

  const handleLoadNFTs = async () => {
    setStatusMessage("Loading NFTs...");
    setNfts([]); // Clear previous NFTs

    const response = await loadNFTs(account, setNfts, setNfts); // Assuming setNfts is used for both setNFTs and setFilteredNFTs for simplicity

    // Check the response success status
    if (response.success) {
      if (response.data && response.data.length > 0) {
        setNfts(response.data); // Update state with loaded NFTs
        setStatusMessage(response.message); // "NFTs loaded successfully!" or "No NFTs found."
      } else {
        // In case data is empty but response is successful
        setStatusMessage("No NFTs found.");
      }
    } else {
      // Handle failure
      setStatusMessage(response.message); // "Failed to load NFTs. Please try again."
    }
  };

  return (
    <div>
      <button onClick={handleLoadNFTs}>Load NFTs</button>
      <p>{statusMessage}</p>
      {nfts.length > 0 && (
        <ul>
          {nfts.map((nft, index) => (
            <li key={index}>
              {nft.name} - {nft.description}
            </li> // Example details
          ))}
        </ul>
      )}
    </div>
  );
};

export default Zed;
