import { createStore } from 'redux';

const initialState = {
    nfts: [],
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_NFTS':
            return { ...state, nfts: action.payload };
        default:
            return state;
    }
}

const store = createStore(rootReducer);

export default store;