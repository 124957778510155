import { Button } from "react-bootstrap";
import "./styles.scss";
import { IpfsImage } from "react-ipfs-image";
import { BarLoader } from "react-spinners";

const ClaimCard = ({ onClickView, title, image, claimed }) => {
  const containsIpfs = (image) => {
    return image.includes("ipfs");
  };

  // Directly calculate the button text based on the claimed status.
  // No need for useState or useEffect if the button text depends solely on the 'claimed' prop.
  const buttonText =
    claimed === true
      ? "Claimed"
      : claimed === false
      ? "Claim"
      : claimed === "offered"
      ? "Claim"
      : "Loading";

  return (
    <div className='nft-card'>
      <div className='image-container'>
        {containsIpfs(image) ? (
          <IpfsImage className='img-size' onClick={onClickView} hash={image} />
        ) : (
          <img
            className='img-size'
            onClick={onClickView}
            src={image}
            alt={title}
          />
        )}
      </div>
      <div className='nft-info'>
        <h3 className='title'>{title}</h3>
      </div>
      {buttonText === "Claim" ? (
        <Button className='primary-button' onClick={onClickView}>
          {buttonText}
        </Button>
      ) : buttonText === "Claimed" ? (
        <Button
          className='secondary-button'
          variant='outline-secondary'
          disabled
        >
          {buttonText}
        </Button>
      ) : (
        <BarLoader color='#ff3400' size={40} />
      )}
    </div>
  );
};

export default ClaimCard;
