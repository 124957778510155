import { Image } from "react-bootstrap";
import Slider from "react-slick";
import "./styles.scss";

import RightArrow from "../../assets/images/chevron-right.png";
import LeftArrow from "../../assets/images/chevron-left.png";

const CarouselSlider = ({
  className,
  children,
  slidesToShow,
  slidesToScroll,
  customSettings,
  centerButtons,
  autoplay,
  initialSlide,
}) => {
  const Arrow = ({ onClick, right }) => {
    return (
      <div
        className={`arrow ${right ? "arrow-right" : "arrow-left"}`}
        onClick={onClick}
      >
        <Image src={right ? RightArrow : LeftArrow} alt='indicator' />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: autoplay,
    initialSlide: initialSlide,
    autoplaySpeed: 4500,
    pauseOnHover: true,
    slidesToShow: slidesToShow ?? 1,
    slidesToScroll: slidesToScroll ?? 1,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow />,
    ...customSettings,
  };

  return (
    <div
      className={`carousel-slider ${className} ${
        centerButtons ? "center-buttons" : ""
      } ${customSettings?.rows === 2 ? "double-row" : "single-row"}`}
    >
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default CarouselSlider;
