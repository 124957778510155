import { useState, useEffect } from "react";
import { Container, Image, Button } from "react-bootstrap";
import "./styles.scss";
import axios from "axios";
import { TabTitle } from "../../utils/GeneralFunctions";
import { changeMetaDescription } from "../../utils/GeneralFunctions";
import { useLoadNFTs } from "../../utils/useLoadNFTs";

import CarouselSlider from "../../components/CarouselSlider";
import NftCard from "../../components/NftCard";
import ViewModal from "../../components/ViewModal";
import VideoModal from "../../components/VideoModal";
import ItemDetailModalContent from "../../components/ItemDetailModalContent";
import ItemOpenModalContent from "../../components/ItemOpenModalContent";
import BasicModalContent from "../../components/BasicModalContent";
import Checkbox from "./components/Checkbox";

import LootIcon from "../../assets/images/announcement.png";

const LootBoxesPage = () => {
  TabTitle("Loot Boxes - Junction");
  changeMetaDescription("View all of your holdings within a given project.");
  const { handleLoadNFTs, nfts, statusMessage } = useLoadNFTs();

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [unlockingVideo, setUnlockingVideo] = useState();
  const [animationUrl, setAnimationUrl] = useState();
  const [showFunctionModal, setShowFunctionModal] = useState(false);
  const [uuid, setUUID] = useState(localStorage.getItem("uuid") || null);
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [showUnboxModal, setShowUnboxModal] = useState(false);
  const [showNoBoxesModal, setNoBoxesModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState({});
  const [lootTests, setLootTests] = useState({});
  const [codeExecuted, setCodeExecuted] = useState(false);
  const [lootboxes, setLootBoxes] = useState([]);
  const [lootboxFilters, setLootboxFilters] = useState(
    () => JSON.parse(localStorage.getItem("lootboxFilters")) || []
  );
  const [isPollingActive, setIsPollingActive] = useState(false);
  const [filteredLootboxes, setFilteredLootboxes] = useState([]);
  const [allCheckbox, setAllCheckbox] = useState(true);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const checked = [checked1, checked2, checked3, checked4, checked5];
  const [contentsImage, setContentsImage] = useState();
  const [claimQR, setClaimQR] = useState();
  const [newTID, setNewTID] = useState();
  useEffect(() => {
    handleLoadNFTs();
  }, [handleLoadNFTs]);
  //console.log(nfts);
  useEffect(() => {
    let interval = null;

    if (isPollingActive) {
      handleLoadNFTs(); // Initial call, optional
      interval = setInterval(() => {
        handleLoadNFTs();
        console.log("Attempting to load NFTs..."); // Periodic refresh
      }, 5000); // Adjust the interval as needed
    }

    return () => {
      if (interval) clearInterval(interval); // Cleanup
    };
  }, [handleLoadNFTs, isPollingActive]);
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axios.get(
          `https://www.junction.club:5000/project/lootboxes`,
          {
            headers: {
              project: "The Junkiverse",
              "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
            },
          }
        );
        setLootboxFilters(res.data.data.lootboxes);
        localStorage.setItem(
          "lootboxFilters",
          JSON.stringify(res.data.data.lootboxes)
        );
      } catch (error) {
        console.error("Failed to fetch lootbox filters:", error);
      }
    };

    // Only fetch filters if they're not already loaded
    if (lootboxFilters.length === 0) {
      fetchFilters();
    }
  }, []); // Empty dependency array to run only on mount

  // Apply filters to NFTs
  useEffect(() => {
    if (nfts && lootboxFilters.length > 0) {
      const filtered = nfts.filter((nft) =>
        lootboxFilters.some(
          (filter) => nft.Taxon === filter.taxon && nft.Issuer === filter.issuer
        )
      );
      setFilteredLootboxes(filtered);
    } else {
      setFilteredLootboxes(nfts); // If no filters are applied, show all NFTs
    }
  }, [nfts, lootboxFilters]);

  const openBox = async (tid, uuid, collection, cid) => {
    try {
      const response = await axios.get(
        `https://junction.club:5000/testloot/${tid}/${uuid}/${collection}`,
        {
          headers: {
            cid: cid,
            account: account,
            "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
          },
        }
      );

      // Check the status code of the early response
      if (response.status === 200) {
        // Handle the early response
        console.log("Received early response:", response.data);

        // Extract animation URLs
        setUnlockingVideo(response.data.unlocking_animation);
        setAnimationUrl(response.data.animation_url);
        setShowVideoModal(true);

        // Fetch the winner image meta
        const winnerMeta = response.data.data.uri.replace(
          "ipfs://",
          "https://ipfs.io/ipfs/"
        );

        // Fetch the winner image
        const winnerImage = await axios.get(winnerMeta);

        // Set the winner image and new TID
        setContentsImage(winnerImage.data.image);
        setNewTID(response.data.data.tid);
      } else {
        // Handle other status codes if needed
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error.message);
    }
  };

  const closeModal = () => {
    setUnlockingVideo(null);
    setAnimationUrl(null);
    setContentsImage(null);
    setNewTID(null);
    setShowVideoModal(false);
  };

  const claimLoot = async (tid, uuid, collection, newTID) => {
    //const res = await axios.get(
    //  `http://localhost:8888/test/${tid}/${uuid}/${collection}/${newTID}`
    //);
    const newQR = await axios.post(
      `https://www.junction.club:5000/claim/easy`,
      {
        tid: newTID,
        uuid: uuid,
        issuer: "rshveNruvzCx5wQ8bXsM2k4Bm3y5EXVJ47",
      }
    );
    setContentsImage(newQR.data);
    console.log(newQR.data);
    setNewTID(null);
    setShowClaimModal(true);
  };

  const count = (valueBefore, valueAfter) => {
    if (lootboxes.length <= 6) {
      return valueBefore;
    } else {
      const lootboxesLength = lootboxes.length;
      if (lootboxesLength <= 2) {
        return 1; // return 1 row for 2 or fewer items
      } else if (lootboxesLength <= 3) {
        return 2; // return 2 rows for 3 items
      } else {
        return valueAfter; // return the default value for more than 3 items
      }
    }
  };

  const setCheck = (index) => {
    if (index === 0) {
      setAllCheckbox(false);
      setChecked1(true);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setChecked5(false);
    }
    if (index === 1) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(true);
      setChecked3(false);
      setChecked4(false);
      setChecked5(false);
    }
    if (index === 2) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(true);
      setChecked4(false);
      setChecked5(false);
    }
    if (index === 3) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(true);
      setChecked5(false);
    }
    if (index === 4) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setChecked5(true);
    }
  };

  const handleClick = (item, index) => {
    // Assuming `item` contains the `taxon` and `issuer` you want to filter by
    // Update the lootboxFilters to the selected filter only
    setLootboxFilters([item]); // This updates the filters to only include the selected one

    // Filter the NFTs based on the selected filter
    const filtered = nfts.filter(
      (nft) => nft.Taxon === item.taxon && nft.Issuer === item.issuer
    );
    setFilteredLootboxes(filtered);

    // Optionally, if you're tracking the selected filter index for UI purposes
    // setCheck(index); // You might need to adjust this part based on your UI logic

    console.log(item.issuer);
    console.log(item.taxon);
    setCheck(index);
  };

  return (
    <div className='lootboxes-page'>
      <Container fluid className='content'>
        <div className='lootbox-filters-container'>
          <div className='lootbox-filters-header d-flex align-items-center'>
            <Image src={LootIcon} alt='Loot Icon' />
            <h1 className='title'>Loot Boxes</h1>
          </div>

          <div className='filters'>
            <Checkbox
              name='all'
              title='all'
              onClick={() => {
                setAllCheckbox(true);
                setChecked1(false);
                setChecked2(false);
                setChecked3(false);
                setChecked4(false);
                setChecked5(false);
              }}
              checked={allCheckbox}
            />
            {lootboxFilters.map((item, index) => {
              return (
                <Checkbox
                  key={index}
                  title={item.name}
                  name={item.name}
                  onClick={() => {
                    handleClick(item, index);
                  }}
                  checked={checked[index]}
                />
              );
            })}
          </div>
        </div>

        <div className='lootboxes'>
          {filteredLootboxes.map((nft, index) => {
            return (
              <NftCard
                key={index}
                title={nft.name}
                image={nft.image}
                onClickView={() => {
                  setShowDetailModal(true);
                  setSelectedNFT(nft);
                }}
                buttonText='Open'
              />
            );
          })}
        </div>
      </Container>

      <ViewModal
        animation={true}
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
      >
        <ItemDetailModalContent
          tag={selectedNFT.name}
          description={
            "Once you retrieve the contents, the lootbox will be burned! See the function used to determine the output "
          }
          link={() => setShowFunctionModal(true)}
          linkText='HERE'
          contents={selectedNFT.attributes}
          button={true}
          image={selectedNFT.image}
          onConfirm={async () => {
            //What Open button does
            setShowDetailModal(false);
            const cid = selectedNFT.URI.replace("ipfs://", "");
            console.log(cid);

            openBox(selectedNFT.NFTokenID, uuid, selectedNFT.Taxon, cid);
            setShowVideoModal(true);
            let intervalId = setInterval(handleLoadNFTs, 5000); // Adjust polling frequency as needed

            // Set duration for polling (e.g., 2 minutes)
            const pollingDuration = 0.6 * 60 * 1000; // in milliseconds

            // Stop polling after the duration expires
            setTimeout(() => {
              clearInterval(intervalId);
              console.log("Polling stopped");
            }, pollingDuration);
          }}
          onCancel={async () => {
            setShowDetailModal(false);
          }}
        />
      </ViewModal>
      <ViewModal show={showNoBoxesModal} onHide={() => setNoBoxesModal(false)}>
        <BasicModalContent
          loadingTitle='No Loot Boxes!'
          buttonText='Close'
          onClickButton={() => {
            setNoBoxesModal(false);
          }}
        />
      </ViewModal>
      <ViewModal
        backdrop='static'
        size='md'
        show={showUnboxModal}
        onHide={() => setShowUnboxModal(false)}
      >
        <ItemOpenModalContent
          loadingTitle='Opening...'
          openedTitle='Unboxed!'
          buttonText='Claim Reward'
          image={selectedNFT.image}
          afterImage={contentsImage}
          onClickButton={async () => {
            claimLoot(selectedNFT.NFTokenID, uuid, selectedNFT.Taxon, newTID);
          }}
        />
      </ViewModal>

      <ViewModal
        backdrop='static'
        size='md'
        show={showClaimModal}
        onHide={() => setShowClaimModal(false)}
      >
        <ItemOpenModalContent
          loadingTitle="Here's your Reward!"
          openedTitle='Scan to Claim'
          openedDescription='or check for push notification'
          claimQR={contentsImage}
          afterImage={contentsImage}
          buttonText='Close'
          onClickButton={() => {
            setShowClaimModal(false);
            setShowUnboxModal(false);
            window.location = "/clear";
          }}
        />
      </ViewModal>
      <ViewModal
        show={showFunctionModal}
        onHide={() => setShowFunctionModal(false)}
      >
        <BasicModalContent
          loadingDescription={selectedNFT.function}
          buttonText='Close'
          onClickButton={() => {
            setShowFunctionModal(false);
          }}
        />
      </ViewModal>
      <ViewModal
        show={showVideoModal}
        backdrop='static'
        onHide={() => setShowVideoModal(false)}
      >
        <VideoModal
          firstVideoSource={unlockingVideo}
          secondVideoSource={animationUrl}
          onClose={closeModal}
        />
      </ViewModal>
    </div>
  );
};

export default LootBoxesPage;
