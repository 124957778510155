import { Link, useLocation } from "react-router-dom";
import "./styles.scss";

export const NavListItem = ({
  renderLeft,
  title,
  doubleSpace,
  path,
  onClick,
}) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  const className = isActive ? "active" : "";

  return (
    <Link
      to={path}
      className={`nav-list-item ${className} ${
        doubleSpace ? "double-space" : ""
      }`}
      onClick={onClick}
    >
      {renderLeft}
      <div className="nav-list-item-title">{title}</div>
    </Link>
  );
};
