import "./styles.scss";
import { Link } from "react-router-dom";
import ReloadIcon from "../../assets/images/reload.png";
import { Image } from "react-bootstrap";

const Section = ({ className, renderLeft, title, children }) => {
  return (
    <div className={`main-section mb-5 ${className}`}>
      <div className='section-header d-flex align-items-center w-100'>
        {renderLeft}
        <h1 className='title'>{title}</h1>
      </div>
      <div className='section-content'>{children}</div>
    </div>
  );
};

export default Section;
