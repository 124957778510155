import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import "./styles.scss";

import DiscordIcon from "../../../../assets/images/discord.png";
import ReloadIcon from "../../../../assets/images/reload.png";
import TwitterIcon from "../../../../assets/images/twitter.png";
import YoutubeIcon from "../../../../assets/images/youtube.png";
import InstagramIcon from "../../../../assets/images/instagram.png";
import RedditIcon from "../../../../assets/images/reddit.png";

const ProjectDetail = ({ projectdetail }) => {
console.log(projectdetail)
  return (
    <div className="project-detail">
      <div className="cover-image-container">
        <Image src={projectdetail.banner}></Image>
      </div>
      <div className="details-container">
        <div className="details">
          <div className="avatar">
            <Image src={projectdetail.avatar}></Image>
          </div>
          <div className="info">
            <h1>The Junkiverse</h1>
          </div>
        </div>
        <div className="socials">
          <Link to={"/The_Junkiverse/clear"}>
            <Image src={ReloadIcon} alt="" />
          </Link>
          <Link target="_blank" to={projectdetail.discord}>
            <Image src={DiscordIcon} alt="" />
          </Link>
          <Link target="_blank" to={projectdetail.twitter}>
            <Image src={TwitterIcon} alt="" />
          </Link>
          <Link target="_blank" to={projectdetail.youtube}>
            <Image src={YoutubeIcon} alt="" />
          </Link>
          <Link target="_blank" to={projectdetail.instagram}>
            <Image src={InstagramIcon} alt="" />
          </Link>
          <Link target="_blank" to={projectdetail.reddit}>
            <Image src={RedditIcon} alt="" />
          </Link>
        </div>
      </div>

      <p className="description">{projectdetail.description}</p>
    </div>
  );
};

export default ProjectDetail;
