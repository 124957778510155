import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";

const LogoutModal = ({
  loadingTitle,
  loadingDescription,
  onClickButton,
  logoutButton,
  logoutButtonText,
  buttonText = "View",
}) => {
  return (
    <div className='logout-open-modal'>
      <h4 className='title'>{loadingTitle}</h4>
      <p className='description'>{loadingDescription}</p>
      <div className='claimed'></div>
      <div className='buttons'>
        <Button className='primary-button' onClick={onClickButton}>
          {buttonText}
        </Button>
        <Button className='primary-button' onClick={logoutButton}>
          {logoutButtonText}
        </Button>
      </div>
    </div>
  );
};

export default LogoutModal;
