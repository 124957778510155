import React from "react";
import VotesList from "../../components/Votes/index.js";
const Vote = () => {
  return (
    <div className='Vote'>
      <VotesList />
    </div>
  );
};
export default Vote;
