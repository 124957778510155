import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import MainPage from "../screens/Main";
import ClaimingPage from "../screens/Claiming";
import LootBoxes from "../screens/LootBoxes";
import OffersPage from "../screens/Offers";
import LogoutPage from "../screens/Logout";
import ClearPage from "../screens/Clear";
import RefreshPage from "../screens/Refresh";
import RefreshOffer from "../screens/RefreshOffer";
import Profile from "../screens/Profile";
import Messages from "../screens/Messages";
import Store from "../screens/Store";
import InventoryPage from "../screens/Inventory";
import Vote from "../screens/Vote";
import Api from "../screens/Api";
import Zed from "../screens/Zed";
import Zed2 from "../screens/Zed2";

//const InventoryPageLazy = lazy(() => import("../screens/Inventory"));

const NavigationContainer = () => {
  return (
    <Routes>
      <Route path='/The_Junkiverse/' element={<MainPage />} />
      <Route path='/The_Junkiverse/claiming' element={<ClaimingPage />} />
      <Route path='/The_Junkiverse/loot-boxes' element={<LootBoxes />} />
      <Route path='/The_Junkiverse/vote' element={<Vote />} />
      <Route path='/The_Junkiverse/offers' element={<OffersPage />} />
      <Route path='/The_Junkiverse/logout' element={<LogoutPage />} />
      <Route path='/The_Junkiverse/clear' element={<ClearPage />} />
      <Route path='/The_Junkiverse/refresh' element={<RefreshPage />} />
      <Route path='/The_Junkiverse/refreshOffer' element={<RefreshOffer />} />
      <Route path='/The_Junkiverse/store' element={<Store />} />
      <Route path='/The_Junkiverse/messages' element={<Messages />} />
      <Route path='/The_Junkiverse/profile' element={<Profile />} />
      <Route path='/The_Junkiverse/api' element={<Api />} />
      <Route path='/The_Junkiverse/zed' element={<Zed />} />
      <Route path='/The_Junkiverse/zed2' element={<Zed2 />} />
      <Route path='/The_Junkiverse/inventory' element={<InventoryPage />} />
    </Routes>
  );
};

export default NavigationContainer;
