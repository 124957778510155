import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Suspense } from "react";

import Layout from "./components/Layout";
import NavigationContainer from "./route";

const App = () => {
  return (
    <Layout>
    <Suspense fallback={<div>Loading...</div>}>
      <NavigationContainer />
      </Suspense>
    </Layout>
  );
}

export default App;
