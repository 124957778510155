import React, { useState, useEffect } from "react";
import ActiveClaims from "./components/ActiveClaims";
import Details from "./components/Details";
import BasicModalContent from "../../components/BasicModalContent";
import ViewModal from "../../components/ViewModal";
import "./styles.scss";
import axios from "axios";
import { TabTitle } from "../../utils/GeneralFunctions";
import { changeMetaDescription } from "../../utils/GeneralFunctions";
import { useLoadNFTs } from "../../utils/useLoadNFTs";

const ClaimingPage = () => {
  TabTitle("Claiming - Junction");
  changeMetaDescription(
    "Browse all active claims available to you based on your holdings."
  );
  const { handleLoadNFTs, nfts, statusMessage } = useLoadNFTs();

  useEffect(() => {
    handleLoadNFTs();
  }, [handleLoadNFTs]);

  const [isClaiming, setClaiming] = useState(false);
  const [activeClaims, setActiveClaims] = useState([]);
  const [codeExecuted, setCodeExecuted] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState([]);
  const [showLoadInventory, setShowLoadInventory] = useState(false);

  useEffect(() => {
    const cachedActiveClaims = localStorage.getItem("activeClaims");

    if (cachedActiveClaims) {
      setActiveClaims(JSON.parse(cachedActiveClaims));
    } else {
      const fetchData = async () => {
        const res = await axios.get(
          "https://www.junction.club:5000/project/claims",
          {
            headers: {
              project: "The Junkiverse",
              "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
            },
          }
        );
        setActiveClaims(res.data.data.activeclaims);
        localStorage.setItem(
          "activeClaims",
          JSON.stringify(res.data.data.activeclaims)
        );
      };
      fetchData();
    }
  }, []);

  const handleClaimSelection = (claim) => {
    setSelectedClaim(claim);
  };

  return (
    <div className='claiming-page'>
      {!isClaiming ? (
        <ActiveClaims
          claims={activeClaims}
          onClaim={() => setClaiming(true)}
          setActive={handleClaimSelection}
        />
      ) : (
        <Details claim={selectedClaim} />
      )}
      <ViewModal
        show={showLoadInventory}
        onHide={() => setShowLoadInventory(false)}
      >
        <BasicModalContent
          loadingTitle='Please Load Inventory'
          loadingDescription='Please Load Inventory First'
          buttonText='Load Inventory'
          onClickButton={() => {
            setShowLoadInventory(false);
            window.location = "/The_Junkiverse/inventory";
          }}
        />
      </ViewModal>
    </div>
  );
};

export default ClaimingPage;
