import React from "react";
import "./VotingDetails.scss";

const VoteDetailsModal = ({ show, onHide, option }) => {
  if (!show) {
    return null;
  }

  return (
    <div className='custom-modal-backdrop'>
      <div className='vote-details-content'>
        <img
          className='vote-details-banner'
          src={option.optionBanner}
          alt={option.optionTitle}
        />

        <h2 className='vote-details-title'>
          {option.optionTitle}
          {": "}
          {option.optionSubtitle}
        </h2>
        <p className='vote-details-desc'>{option.optionLongDesc}</p>
        <p className='vote-details-infoTitle'>
          <span className='highlight'>
            {option.optionInfoTitle}
            {": "}
          </span>
          {option.optionInfo}
        </p>

        <button className='primary-button' onClick={onHide}>
          Close
        </button>
      </div>
    </div>
  );
};

export default VoteDetailsModal;
