import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";
import { IpfsImage } from 'react-ipfs-image';

const JustQR = ({
    openedTitle,
    openedDescription,
    onClickClose,
    claimQR,
    buttonText,
    buttonText2,
    link
}) => {

    return (
        <div className="item-open-modal">
                    <div className="claimed">
                        <div className="image-container"><img className="image-container" src={claimQR} /></div>
                         <h4 className="title">{openedTitle}</h4>
                         <p className="description">{openedDescription}</p>

                        <Button className="primary-button" onClick={onClickClose}>{buttonText}</Button>
                        {link ? (<Button className="primary-button" onClick={link}>{buttonText2}</Button>) : null}
                    </div>
        </div>
    )
}

export default JustQR;

