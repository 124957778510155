// nftUtils.js
import axios from "axios";

export const loadNFTs = async (account, setNFTs, setFilteredNFTs, dispatch) => {
  if (!account) {
    // Return a message indicating the user needs to sign in
    return { success: false, message: "Please sign in to load NFTs." };
  }

  try {
    const res = await axios.get(
      "https://www.junction.club:5000/project/The_Junkiverse/nfts",
      {
        headers: {
          account: account,
          "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
        },
      }
    );
    const account_nfts = res.data;

    if (account_nfts.length === 0) {
      return { success: true, message: "No NFTs found.", data: account_nfts };
    } else {
      // Directly setting the NFTs without checking the cache
      setFilteredNFTs(account_nfts);
      setNFTs(account_nfts);
      if (dispatch) {
        dispatch({ type: "SET_NFTS", payload: account_nfts });
      }
      return {
        success: true,
        message: "NFTs loaded successfully.",
        data: account_nfts,
      };
    }
  } catch (error) {
    console.error("Failed to load NFTs:", error);
    // Return an error message
    return {
      success: false,
      message: "Failed to load NFTs. Please try again.",
    };
  }
};
