import { Button, Container, Image } from "react-bootstrap";
import "./styles.scss";
import { useState } from "react";
import { TabTitle } from "../../utils/GeneralFunctions";

import BasicModalContent from "../../components/BasicModalContent";
import ViewModal from "../../components/ViewModal";


const Profile = () => {
    TabTitle('Profile - Junction');
    const [showModal, setShowModal] = useState(false);
    const [logoutText, setLogoutText] = useState("");
    const [logoutButtonText, setLogoutButtonText] = useState("");
    const [codeExecuted, setCodeExecuted] = useState(false);
    const logoutModal = () => {
        setShowModal(true)
        setLogoutText("Coming Soon")
    }
    if (!codeExecuted) {
        setCodeExecuted(true)
        logoutModal()
    }
    return (
        <Container className="offers-page">
            <ViewModal show={showModal} onHide={() => setShowModal(false)}>
                <BasicModalContent
                    loadingTitle={logoutText}
                    buttonText="Close"
                    onClickButton={() => {
                        {
                            setShowModal(false);
                        }
                    }}
                />
            </ViewModal>
        </Container>
    )
};

export default Profile;
