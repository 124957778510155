import { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";
import ViewModal from "../../components/ViewModal";
import LoginOpenModalContent from "../../components/LoginOpenModalContent";
import BasicModalContent from "../../components/BasicModalContent";

const Header = ({ onClickMenu }) => {
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [user_token, setUserToken] = useState(
    localStorage.getItem("user_token") || null
  );
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showAlreadySignedInModal, setShowAlreadySignedInModal] =
    useState(false);
  const [codeExecuted, setCodeExecuted] = useState(false);
  const [qr, setQr] = useState();
  const [uuid, setUuid] = useState();
  const [onMobile, setOnMobile] = useState();
  const [connectButton, setConnectButton] = useState("Connect Wallet");

  async function login() {
      if (!account) {
          if (isXApp()) {
              // Implement XUMM login flow
              const clientId = "e4273ab6-38d1-44f2-b6c8-a2f90b809f34";
              const redirectUri = "https://www.junction.club";
              const urlParams = new URLSearchParams(window.location.search);
              const state = urlParams.get("state");
              const xummAuthUrl = `https://xumm.app/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=openid&state=${state}`;
              window.location.href = xummAuthUrl;
          } else {
              setShowSignInModal(true);
          }
    } else {
      setShowAlreadySignedInModal(true);
    }
    }

    useEffect(() => {
        const handleXummRedirect = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            const state = urlParams.get("state");

            if (code) {
                const response = await axios.post(
                    "https://www.junction.club/exchange_code",
                    {
                        code: code,
                        state: state,
                    }
                );

                const { access_token, user_account } = response.data;
                setAccount(account);
                setUserToken(access_token);
                localStorage.setItem("account", user_account);
                localStorage.setItem("user_token", access_token);

                // Call the custom backend endpoint to create a new payload
                const createPayloadResponse = await axios.post(
                    "https://www.junction.club/create_payload",
                    {
                        access_token: access_token,
                    }
                );
                const { payload_uuid } = createPayloadResponse.data;
                setUuid(payload_uuid);
                localStorage.setItem("uuid", payload_uuid);

                window.location = "/The_Junkiverse/clear";
            }
        };

        handleXummRedirect();
    }, []);

  const onLoad = async () => {
    if (!codeExecuted && !account) {
      setCodeExecuted(true);
      const getQR = await axios.get("https://www.junction.club:5000/login", {
        headers: {
          "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
        },
      });
      console.log(getQR.data);
      setQr(getQR.data.qrUrl);
      setOnMobile(getQR.data.next);
      setUuid(getQR.data.uuid);
      localStorage.setItem("uuid", getQR.data.uuid);
    } else if (!codeExecuted && account) {
      setCodeExecuted(true);
      setConnectButton("Wallet Connected");
    }
  };

  onLoad();

  const getAccountInfo = async () => {
    const getAccount = await axios.get(
      `https://www.junction.club:5000/payload`,
      {
        headers: {
          uuid: uuid,
          "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
        },
      }
    );
    const account = getAccount.data.data.response.account;
    const user_token = getAccount.data.data.application.issued_user_token;
    setAccount(account);
    setUserToken(user_token);
    localStorage.setItem("account", account);
    localStorage.setItem("user_token", user_token);
    window.location = "/The_Junkiverse/clear";
    };

    function isXApp() {
        console.log(navigator.userAgent)
        return navigator.userAgent.includes("XAPP");
    }

  return (
    <header>
      <div className="menu-button">
        <Button className="secondary-button" onClick={onClickMenu}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
      </div>
      <h1 className="title">The Junkiverse</h1>
      <div className="right-section">
        <InputGroup className="searchbar">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            placeholder="PROJECTS, COLLECTIONS, USERS"
            aria-label="search"
            aria-describedby="search-1"
          />
        </InputGroup>

        <div className="button-continer">
          <Button className="primary-button" onClick={login}>
            {connectButton}
          </Button>
        </div>
        <ViewModal backdrop="static" size="md" show={showSignInModal}>
          <LoginOpenModalContent
            loadingTitle="Signing in..."
            openedTitle="You're all set!"
            buttonText="Close"
            image={qr}
            uuid={uuid}
            onClickButton={() => {
              setShowSignInModal(false);
            }}
            onMobile={() =>
              window.open(
                onMobile,
                "popup",
                `width=600, height=800, top=(window.screen.height / 2) - (h / 2), left=(window.screen.width / 2) - (w / 2), location=no`
              )
            }
            getAccountButton={() => {
              getAccountInfo();
              setShowSignInModal(false);
            }}
          />
        </ViewModal>
        <ViewModal backdrop="static" size="md" show={showAlreadySignedInModal}>
          <BasicModalContent
            loadingTitle="Signed In As:"
            loadingDescription={account}
            buttonText="Close"
            onClickButton={() => {
              setShowAlreadySignedInModal(false);
            }}
          />
        </ViewModal>
      </div>
    </header>
  );
};

export default Header;
