export const TabTitle = (newTitle) => {
    return (document.title = newTitle);
}

export const changeMetaDescription = (newDescription) => {
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute('content', newDescription);
    } else {
        // If a meta description tag does not exist, create one and append it to the head
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = newDescription;
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
    }
};
