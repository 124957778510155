import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

// Internal function to load NFTs
const loadNFTs = async (account) => {
  console.log("API call started for account:", account);
  if (!account) {
    return { success: false, message: "Please sign in to load NFTs." };
  }

  try {
    const res = await axios.get(
      "https://www.junction.club:5000/project/The_Junkiverse/nfts",
      {
        headers: {
          account: account,
          "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
        },
      }
    );
    const account_nfts = res.data;
    return {
      success: true,
      message:
        account_nfts.length === 0
          ? "No NFTs found."
          : "NFTs loaded successfully.",
      data: account_nfts,
    };
  } catch (error) {
    console.error("Failed to load NFTs:", error);
    return {
      success: false,
      message: "Failed to load NFTs. Please try again.",
    };
  }
};

export const useLoadNFTs = () => {
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [statusMessage, setStatusMessage] = useState("");
  const [nfts, setNfts] = useState([]);
  const dispatch = useDispatch();

  const handleLoadNFTs = useCallback(async () => {
    if (!account) return;

    const response = await loadNFTs(account);

    if (response.success) {
      setNfts(response.data);
      setStatusMessage(response.message);
      dispatch({ type: "SET_NFTS", payload: response.data });
    } else {
      setStatusMessage(response.message);
    }
  }, [account, dispatch]);

  //   useEffect(() => {
  //     handleLoadNFTs(); // Initial load

  //     const interval = setInterval(() => {
  //       handleLoadNFTs(); // Periodic refresh
  //     }, 5000); // Adjust the interval as needed

  //     return () => clearInterval(interval); // Cleanup on unmount
  //   }, [handleLoadNFTs]);

  // Listen for account changes in localStorage
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "account") {
        setAccount(localStorage.getItem("account") || null);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return { handleLoadNFTs, nfts, statusMessage, account };
};
