import "./styles.scss";

const Tag = ({ children, className, backgroundColor }) => {
  return (
    <div
      className={`${className} tag`}
      style={{
        backgroundColor: backgroundColor ?? '#FF3400',
        borderColor: backgroundColor ?? '#FF3400'
      }}
    >
      {children}
    </div>
  )
};

export default Tag;