import React, { useState, useEffect } from "react";
import "./VotingPage.scss";
import {
  getActiveVotes,
  getVotingOptions,
  getValidNFTs,
  castVote,
  getTotalVotes,
} from "../../utils/votingService";
import VoteModal from "../../components/StoreModal";
import DetailedVoteModal from "../../components/Votes/VoteDetails";

const VotingPage = () => {
  const [votes, setVotes] = useState([]);
  const [selectedVoteId, setSelectedVoteId] = useState("");
  const [options, setOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [expandedOption, setExpandedOption] = useState(null);
  const [totalVotes, setTotalVotes] = useState(0);
  const selectedVote = votes.find((vote) => vote.id === selectedVoteId);

  // Fetch active votes
  useEffect(() => {
    const fetchActiveVotes = async () => {
      const response = await getActiveVotes();
      if (response.status) {
        setVotes(response.votes);
        // Optionally set the first active vote as selected
        if (response.votes.length > 0) {
          setSelectedVoteId(response.votes[0].id);
        }
      } else {
        console.error("Failed to fetch active votes");
      }
    };
    fetchActiveVotes();
  }, []);

  // const handleVoteSelection = (event) => {
  //   setSelectedVoteId(event.target.value); // Assuming you get the voteId from an event
  // };

  const handleVoteSubmission = async (optionId) => {
    const account = localStorage.getItem("account"); // Ensure this is the correct way you're obtaining the user's account

    try {
      const numVotes = await getValidNFTs(account); // Ensure this returns the vote power correctly

      if (numVotes > 0) {
        const response = await castVote(
          account,
          selectedVoteId,
          optionId,
          numVotes
        );

        if (response && response.status) {
          setModalMessage(response.message || "Your vote has been cast!");
          fetchOptions(); // Refresh the options to reflect the new vote counts
          await loadTotalVotes(selectedVoteId); // Update total votes display
        } else {
          // Handle failure based on API response
          setModalMessage(response.message || "Failed to cast vote.");
        }
      } else {
        // Handle case where numVotes is 0 or invalid
        setModalMessage("You do not have the required NFTs to vote.");
      }
    } catch (error) {
      console.error("Error during vote submission:", error);
      setModalMessage("An error occurred while casting your vote.");
    } finally {
      setIsModalOpen(true); // Show the modal with the message in any case
    }
  };

  // Function to fetch the updated options after voting
  const fetchOptions = async () => {
    if (selectedVoteId) {
      const response = await getVotingOptions(selectedVoteId);
      if (response.status) {
        setOptions(response.options);
      } else {
        console.error("Failed to fetch vote options");
      }
    }
  };
  const loadTotalVotes = async () => {
    const result = await getTotalVotes(selectedVoteId);
    if (result && result.status) {
      setTotalVotes(result.totalVotes);
    } else {
      console.log("Could not load total votes", result);
    }
  };
  useEffect(() => {
    if (selectedVoteId) {
      loadTotalVotes();
    }
  }, [selectedVoteId]);
  // Call this function in your useEffect for initial load as well
  useEffect(() => {
    fetchOptions();
  }, [selectedVoteId]);

  return (
    <div className='voting-container'>
      <div className='voteCounter'>
        Total Votes:<p className='voteCounterNumber'> {totalVotes}</p>
      </div>
      <div className='vote-options-cards'>
        {options.map((option) => (
          <div
            key={option.id}
            className='vote-option-card'
            onClick={() => setExpandedOption(option)}
          >
            <img className='optionImg' src={option.optionImg} />

            <p className='optionTitle'>{option.optionTitle}</p>

            <p className='optionSubtitle'>{option.optionSubtitle}</p>
            <div className='divider'>
              <p className='details-expand'></p>
            </div>
            <p className='optionDesc'>{option.optionLongDesc}</p>
            <div className='divider'>
              <p className='details-expand'>Expand</p>
            </div>
            <p className='votesCount'>Votes: {option.votesCount || "0"}</p>
            <button
              className='vote-button'
              onClick={(e) => {
                e.stopPropagation();
                handleVoteSubmission(option.id);
              }}
            >
              Vote
            </button>
            {/* Add additional elements here as needed */}
          </div>
        ))}
      </div>
      <VoteModal
        show={isModalOpen}
        message={modalMessage}
        onHide={() => setIsModalOpen(false)}
      />
      <DetailedVoteModal
        show={!!expandedOption}
        option={expandedOption}
        onHide={() => setExpandedOption(null)}
      />
      {/* <h1>Active Votes</h1> */}

      {/* {votes.length > 0 ? (
        <>
          <select value={selectedVoteId} onChange={handleVoteSelection}>
            {votes.map((vote) => (
              <option key={vote.id} value={vote.id}>
                {vote.title}
              </option>
            ))}
          </select>
          {selectedVote && <p>{selectedVote.description}</p>}
        </>
      ) : (
        <p>No active votes at this time.</p>
      )} */}
    </div>
  );
};

export default VotingPage;
