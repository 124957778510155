import { Button } from "react-bootstrap";
import "./styles.scss";

const AnnouncementCard = ({ title, subtitle, description, onClick, image, button }) => {
  return (
    <div className="announcement-card">
      <div className="card-content">
        <h1 className="title">{title}</h1>
        <h4 className="subtitle">{subtitle}</h4>
        <p className="paragraph">{description}</p>
        <Button className="primary-button" onClick={onClick}>
          {button}
        </Button>
      </div>
      <div className="card-image-container">
        <img src={image} className="card-image-container" />
      </div>
    </div>
  );
};

export default AnnouncementCard;
