import { useState } from "react";
import "./styles.scss";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";

const Layout = ({ children }) => {
  const [sidebarActive, setSidebarActive] = useState(false);

  const onCloseSidebar = () => {
    setSidebarActive(false);
  };

  return (
    <div className="layout d-flex flex-row w-100">
      <Sidebar sidebarActive={sidebarActive} closeSidebar={onCloseSidebar} />
      <div className="app-content w-100">
        <Header onClickMenu={() => setSidebarActive(true)} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
