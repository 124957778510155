import { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import "./styles.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import store from "../../store";
import { TabTitle } from "../../utils/GeneralFunctions";
import { changeMetaDescription } from "../../utils/GeneralFunctions";
import { useLoadNFTs } from "../../utils/useLoadNFTs";

import Checkbox from "./components/Checkbox";
import ItemDetailModalContent from "../../components/ItemDetailModalContent";
import BasicModalContent from "../../components/BasicModalContent";
import ViewModal from "../../components/ViewModal";
import NftCard from "../../components/NftCard";
import CarouselSlider from "../../components/CarouselSlider";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReloadIcon from "../../assets/images/reload.png";

import FilterIcon from "../../assets/images/announcement.png";

const InventoryPage = () => {
  TabTitle("Inventory - Junction");
  changeMetaDescription("View all of your holdings within a given project.");
  const { nfts, handleLoadNFTs } = useLoadNFTs();
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [userToken, setUserToken] = useState(
    localStorage.getItem("userToken") || null
  );
  const [showModal, setShowModal] = useState(false);
  const [filteredNFTs, setFilteredNFTs] = useState([]);
  //const [nfts, setNFTs] = useState([]);
  const [Collection1, setCollection1] = useState(false);
  const [Collection0, setCollection0] = useState(false);
  const [allCheckbox, setAllCheckbox] = useState(true);
  const [showPleaseSignInModal, setPleaseSignInModal] = useState(false);
  const [codeExecuted, setCodeExecuted] = useState(false);
  const [filters, setFilters] = useState([]);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const checked = [checked1, checked2, checked3, checked4, checked5, checked6];
  const dispatch = useDispatch();
  useEffect(() => {
    handleLoadNFTs();
  }, [handleLoadNFTs]);
  useEffect(() => {
    if (account) {
      // Attempt to load NFTs from cache first
      const cachedNFTs = localStorage.getItem("nfts");
      if (cachedNFTs) {
        const cachedAccountNFTs = JSON.parse(cachedNFTs);
        setFilteredNFTs(cachedAccountNFTs);
      } else {
        // If not in cache, load from API via the hook
        handleLoadNFTs();
      }
    } else {
      setPleaseSignInModal(true); // Show sign-in modal if there's no account
    }
  }, [account, handleLoadNFTs]);

  // Listen for changes in the nfts state from useLoadNFTs and update filteredNFTs accordingly
  useEffect(() => {
    if (nfts && nfts.length > 0) {
      setFilteredNFTs(nfts);
      // Optionally, cache the NFTs if they're not already cached
      localStorage.setItem("nfts", JSON.stringify(nfts));
    }
  }, [nfts]);
  useEffect(() => {
    const cachedFilters = localStorage.getItem("filters");

    if (cachedFilters) {
      setFilters(JSON.parse(cachedFilters));
    } else {
      const fetchData = async () => {
        const res = await axios.get(
          `https://www.junction.club:5000/project/inventory`,
          {
            headers: {
              project: "The Junkiverse",
              "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
            },
          }
        );
        setFilters(res.data.data.inventory);
        localStorage.setItem(
          "filters",
          JSON.stringify(res.data.data.inventory)
        );
      };
      fetchData();
    }
  }, []);

  const [selectedNFT, setSelectedNFT] = useState({});
  const [selectedNFTImage, setSelectedNFTImage] = useState(null);

  const count = (valueBefore, valueAfter) => {
    if (nfts.length <= 6) {
      return valueBefore;
    } else {
      const filteredNftsLength = filteredNFTs.length;
      if (filteredNftsLength <= 2) {
        return 1; // return 1 row for 2 or fewer items
      } else if (filteredNftsLength <= 4) {
        return 2; // return 2 rows for 3 items
      } else {
        return valueAfter; // return the default value for more than 3 items
      }
    }
  };

  const setCheck = (index) => {
    if (index === 0) {
      setAllCheckbox(false);
      setChecked1(true);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setChecked5(false);
      setChecked6(false);
    }
    if (index === 1) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(true);
      setChecked3(false);
      setChecked4(false);
      setChecked5(false);
      setChecked6(false);
    }
    if (index === 2) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(true);
      setChecked4(false);
      setChecked5(false);
      setChecked6(false);
    }
    if (index === 3) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(true);
      setChecked5(false);
      setChecked6(false);
    }
    if (index === 4) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setChecked5(true);
      setChecked6(false);
    }
    if (index === 5) {
      setAllCheckbox(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setChecked5(false);
      setChecked6(true);
    }
  };

  const handleClick = (item, index) => {
    setFilteredNFTs(
      nfts.filter(
        (nft) => nft.Taxon === item.taxon && nft.Issuer === item.issuer
      )
    );
    // console.log(
    //   setFilteredNFTs(nfts.filter((nft) => nft.Taxon === item.taxon))
    // );
    // console.log(
    //   setFilteredNFTs(nfts.filter((nft) => nft.Issuer === item.issuer))
    // );

    console.log(item.issuer);
    console.log(item.taxon);
    setCheck(index);
  };

  return (
    <div className='inventory-page'>
      <Container fluid className='content'>
        <div className='filters-container'>
          <div className='filters-header d-flex align-items-center'>
            <Image src={FilterIcon} alt='Filter Icon' />
            <h1 className='title'>Filter</h1>
            <button
              className='refresh'
              onClick={handleLoadNFTs}
              style={{ border: "none", background: "none" }}
            >
              <img
                src={ReloadIcon}
                alt='Reload'
                style={{ cursor: "pointer" }}
              />
            </button>
          </div>

          <div className='filters'>
            <Checkbox
              name='all'
              title='all'
              onClick={() => {
                setFilteredNFTs(nfts);
                setAllCheckbox(true);
                setChecked1(false);
                setChecked2(false);
                setChecked3(false);
                setChecked4(false);
                setChecked5(false);
                setChecked6(false);
              }}
              checked={allCheckbox}
            />
            {filters.map((item, index) => {
              return (
                <Checkbox
                  key={index}
                  title={item.name}
                  name={item.name}
                  onClick={() => {
                    handleClick(item, index);
                  }}
                  checked={checked[index]}
                />
              );
            })}
          </div>
        </div>

        <div className='inventory-items'>
          {filteredNFTs.map((item, index) => {
            return (
              <NftCard
                key={index}
                image={
                  item.name.includes("Junkie")
                    ? `https://junction-project-images.s3.amazonaws.com/xrpjunkies/images/${item.name.replace(
                        /^Junkie #/,
                        ""
                      )}.png`
                    : item.name.includes("Droid")
                    ? `https://junction-project-images.s3.amazonaws.com/xrpjunkies/droids/${item.name.replace(
                        /^Drowsy Droid #/,
                        ""
                      )}.png`
                    : item.image
                }
                title={item.name}
                onClickView={() => {
                  setShowModal(true);
                  setSelectedNFT(item);
                  setSelectedNFTImage(
                    item.name.includes("Junkie")
                      ? `https://junction-project-images.s3.amazonaws.com/xrpjunkies/images/${item.name.replace(
                          /^Junkie #/,
                          ""
                        )}.png`
                      : item.name.includes("Droid")
                      ? `https://junction-project-images.s3.amazonaws.com/xrpjunkies/droids/${item.name.replace(
                          /^Drowsy Droid #/,
                          ""
                        )}.png`
                      : item.image
                  );
                }}
              />
            );
          })}
        </div>
      </Container>

      <ViewModal show={showModal} onHide={() => setShowModal(false)}>
        <ItemDetailModalContent
          image={selectedNFTImage}
          tag={selectedNFT.name}
          description={
            selectedNFT.description
              ? selectedNFT.description
              : selectedNFT.function
          }
          stats={Array(6).fill({})}
          metadata={selectedNFT.attributes}
        />
      </ViewModal>
      <ViewModal
        show={showPleaseSignInModal}
        onHide={() => setPleaseSignInModal(false)}
      >
        <BasicModalContent
          loadingTitle='Sign in to see your inventory'
          buttonText='Close'
          onClickButton={() => {
            setPleaseSignInModal(false);
          }}
        />
      </ViewModal>
    </div>
  );
};

export default InventoryPage;
