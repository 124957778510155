import axios from "axios";

const baseUrl = "https://junction.club:5000"; // Adjust based on your server's URL

// Function to get voting options for a specific vote
export const getVotingOptions = async (voteId) => {
  try {
    const response = await fetch(
      `${baseUrl}/get-voting-options?voteId=${voteId}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting voting options:", error);
    throw error;
  }
};
export const getActiveVotes = async () => {
  const response = await fetch(`${baseUrl}/get-active-votes`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data;
};
export const getTotalVotes = async (voteId) => {
  try {
    const response = await fetch(`${baseUrl}/get-total-votes/${voteId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch total votes");
    }
    const data = await response.json();
    return data; // Returns the entire response payload
  } catch (error) {
    console.error("Error:", error);
    return null; // Or handle errors as appropriate for your application
  }
};

export const getValidNFTs = async (account) => {
  try {
    const response = await axios.get(`${baseUrl}/get-nfts?account=${account}`);

    // Assuming the server sends back the length directly
    return response.data.validNFTsLength;
  } catch (error) {
    console.error("Error fetching NFTs:", error);
    return 0;
  }
};
// Function to cast a vote
export const castVote = async (account, voteId, optionId, numVotes) => {
  try {
    const response = await fetch(`${baseUrl}/cast-vote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account, voteId, optionId, numVotes }),
    });
    if (!response.ok) {
      throw new Error("Failed to cast or change vote");
    }
    const data = await response.json();
    return data; // Returns the response payload for further handling
  } catch (error) {
    console.error("Error casting or changing vote:", error);
    return null; // Or handle errors as appropriate for your application
  }
};
