import { Button, Container, Image } from "react-bootstrap";
import "./styles.scss";
import { useState } from "react";

import LogoutModal from "../../components/LogoutModal";
import ViewModal from "../../components/ViewModal";


const ClearPage = () => {
        localStorage.removeItem("activeClaims")
        localStorage.removeItem("announcements")
        localStorage.removeItem("filters")
        localStorage.removeItem("nfts")
        localStorage.removeItem("projectdetails")
        localStorage.removeItem("roadmap")
    localStorage.removeItem("team")
    localStorage.removeItem("lootboxFilters")
    window.location = "/The_Junkiverse/"


  return 
};

export default ClearPage;
