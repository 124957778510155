import { Image } from "react-bootstrap";
import "./styles.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { TabTitle } from "../../utils/GeneralFunctions";
import { useLoadNFTs } from "../../utils/useLoadNFTs";

import ProjectDetail from "./components/ProjectDetail";
import Announcements from "./components/Announcements";
import Roadmap from "./components/Roadmap";
import Team from "./components/Team";
import Section from "../../components/Section";

import AnnouncementIcon from "../../assets/images/announcement.png";
import RoadmapIcon from "../../assets/images/roadmap.png";
import TeamIcon from "../../assets/images/team.png";

const MainPage = () => {
  TabTitle("Junction");
  const { handleLoadNFTs, nfts, statusMessage } = useLoadNFTs();

  useEffect(() => {
    handleLoadNFTs();
  }, [handleLoadNFTs]);
  const [announcements, setAnnouncements] = useState([]);
  const [roadmap, setRoadmap] = useState([]);
  const [team, setTeam] = useState([]);
  const [projectdetails, setProjectDetails] = useState({});
  const [codeExecuted, setCodeExecuted] = useState(false);

  useEffect(() => {
    const cachedAnnouncements = localStorage.getItem("announcements");
    const cachedRoadmap = localStorage.getItem("roadmap");
    const cachedTeam = localStorage.getItem("team");
    const cachedProjectDetails = localStorage.getItem("projectdetails");

    if (
      cachedAnnouncements &&
      cachedRoadmap &&
      cachedTeam &&
      cachedProjectDetails
    ) {
      setAnnouncements(JSON.parse(cachedAnnouncements));
      setRoadmap(JSON.parse(cachedRoadmap));
      setTeam(JSON.parse(cachedTeam));
      setProjectDetails(JSON.parse(cachedProjectDetails));
    } else {
      const fetchData = async () => {
        const res = await axios.get("https://www.junction.club:5000/project", {
          headers: {
            project: "The Junkiverse",
            "x-api-key": process.env.REACT_APP_JUNCTION_KEY,
          },
        });
        setAnnouncements(res.data.data.home.announcements);
        setRoadmap(res.data.data.home.roadmap);
        setTeam(res.data.data.home.team);
        setProjectDetails(res.data.data.home.projectdetail);
        localStorage.setItem(
          "announcements",
          JSON.stringify(res.data.data.home.announcements)
        );
        localStorage.setItem(
          "roadmap",
          JSON.stringify(res.data.data.home.roadmap)
        );
        localStorage.setItem("team", JSON.stringify(res.data.data.home.team));
        localStorage.setItem(
          "projectdetails",
          JSON.stringify(res.data.data.home.projectdetail)
        );
      };
      fetchData();
    }
  }, []);

  return (
    <div className='main-page container-lg'>
      <ProjectDetail projectdetail={projectdetails} />

      <Section
        className='announcements-section'
        title='Announcements'
        renderLeft={<Image src={AnnouncementIcon} alt='Announcement Icon' />}
      >
        <Announcements announcements={announcements} />
      </Section>

      <Section
        className='roadmap-section'
        title='Roadmap'
        renderLeft={<Image src={RoadmapIcon} alt='Roadmap Icon' />}
      >
        <Roadmap roadmap={roadmap} />
      </Section>

      <Section
        className='team-section'
        title='Team'
        renderLeft={<Image src={TeamIcon} alt='Team Icon' />}
      >
        <Team team={team} />
      </Section>
    </div>
  );
};

export default MainPage;
