import React from "react";
import "./Store.styles.scss";
import { BarLoader } from "react-spinners"; // Assume you'll create this CSS file next

const StoreModal = ({ show, onHide, message, isLoading }) => {
  if (!show) {
    return null;
  }

  return (
    <div className='custom-modal-backdrop'>
      <div className='custom-modal-content'>
        <h2 className='store-modal-title'>Minting Status</h2>
        <p className='store-modal-message'>{message}</p>
        {isLoading && (
          <BarLoader className='store-loader' color='#ff3400' size={40} />
        )}
        <button className='primary-button' onClick={onHide}>
          Close
        </button>
      </div>
    </div>
  );
};

export default StoreModal;
