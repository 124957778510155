import { Button } from "react-bootstrap";
import "./styles.scss";
import { IpfsImage } from 'react-ipfs-image';

const NftCard = ({ onClickView, title, buttonText, image, isClaimed }) => {

  const containsIpfs = (image) => {
    return image.includes("ipfs");
  };

  return (
    <div className="nft-card">
      <div className="image-container">
        <a style={{ cursor: "pointer" }}>
          {containsIpfs(image) ? (
            <IpfsImage className="img-size" onClick={onClickView} hash={image} />
          ) : (
            <img className="img-size" onClick={onClickView} src={image} />
          )}
        </a>
      </div>
      <div className="nft-info">
        <h3 className='title'>{title}</h3>
      </div>
    </div>
  );
}

export default NftCard;
