import { Button, Container, Image } from "react-bootstrap";
import "./styles.scss";
import { useState } from "react";

import LogoutModal from "../../components/LogoutModal";
import ViewModal from "../../components/ViewModal";

const RefreshPage = () => {
  localStorage.removeItem("filters");
  localStorage.removeItem("nfts");
  window.location = "/The_Junkiverse/inventory";

  return;
};

export default RefreshPage;
