import { Modal } from "react-bootstrap";
import "./styles.scss";

const ViewModal = ({
  backdrop,
  show,
  animation,
  onHide,
  size = "lg",
  children,
}) => {
  return (
    <Modal
      className='view-modal'
      backdrop={backdrop}
      show={show}
      animation={animation}
      onHide={onHide}
      size={size}
      aria-labelledby='contained-view-modal-vcenter'
      centered
    >
      {children}
    </Modal>
  );
};

export default ViewModal;
