import { Image, Navbar, NavbarBrand } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
  faBolt,
  faBoxArchive,
  faBoxOpen,
  faCartShopping,
  faHome,
  faMessage,
  faGift,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { NavListItem } from "./components/ListItem";
import "./styles.scss";

import Logo from "../../assets/images/logo.png";

const Sidebar = ({ closeSidebar, sidebarActive }) => {
  return (
    <div className='sidebar-container'>
      <div
        className={`sidebar-overlay ${sidebarActive ? "active" : ""}`}
        onClick={closeSidebar}
      ></div>
      <Navbar className={`sidebar ${sidebarActive ? "active" : ""}`}>
        <NavbarBrand>
          <Link to='/The_Junkiverse/'>
            <Image className='img-fluid logo' src={Logo} alt='Logo' />
          </Link>
        </NavbarBrand>
        <div className='nav-items'>
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faHome} />}
            title='Home'
            path='/The_Junkiverse/'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faBoxArchive} />}
            title='Inventory'
            path='/The_Junkiverse/inventory'
            onClick={closeSidebar}
          />

          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faBolt} />}
            title='Claiming'
            path='/The_Junkiverse/claiming'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faBoxOpen} />}
            title='Loot Boxes'
            path='/The_Junkiverse/loot-boxes'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faBoxOpen} />}
            title='Vote'
            path='/The_Junkiverse/vote'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faGift} />}
            title='Offers'
            path='/The_Junkiverse/offers'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faCartShopping} />}
            title='Store'
            path='/The_Junkiverse/store'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faUser} />}
            title='Profile'
            path='/The_Junkiverse/profile'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faMessage} />}
            title='Messages'
            path='/The_Junkiverse/messages'
            onClick={closeSidebar}
          />
          <NavListItem
            renderLeft={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
            title='LogOut'
            path='/The_Junkiverse/logout'
            onClick={closeSidebar}
          />
        </div>
      </Navbar>
    </div>
  );
};

export default Sidebar;
