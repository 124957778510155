import { Button } from "react-bootstrap";
import "./styles.scss";

const ActiveClaimCard = ({
  date,
  title,
  description,
  price,
  onClaim,
  image,
  active,
}) => {
  return (
    <div className="active-claim-card">
      <div className="card-content-container">
        <div className="card-date">{date}</div>
        <h1 className="card-title">{title}</h1>
        <p className="card-description">{description}</p>
        <div className="card-buttons">
          <Button className="secondary-button" onClick={onClaim}>
            Price: 0 XRP
          </Button>
          {active && (
            <Button className="primary-button" onClick={onClaim}>
              Claim
            </Button>
          )}

        </div>
      </div>

      <div className="card-image-container">
        <img src={image} />
      </div>
    </div>
  );
};

export default ActiveClaimCard;
