import { Button, Container, Image } from "react-bootstrap";
import "./styles.scss";
import { useState } from "react";
import { TabTitle } from "../../utils/GeneralFunctions";

import LogoutModal from "../../components/LogoutModal";
import ViewModal from "../../components/ViewModal";

const LogoutPage = () => {
  TabTitle("Logout - Junction");
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );
  const [showModal, setShowModal] = useState(false);
  const [logoutText, setLogoutText] = useState("");
  const [logoutButtonText, setLogoutButtonText] = useState("");
  const [codeExecuted, setCodeExecuted] = useState(false);
  const logoutModal = () => {
    setShowModal(true);
    if (!account) {
      setLogoutText("You're not signed in");
      setLogoutButtonText("Clear Cache");
    } else {
      setLogoutText(" ");
      setLogoutButtonText("Logout");
    }
  };
  const logout = () => {
    localStorage.removeItem("account");
    localStorage.removeItem("user_token");
    localStorage.removeItem("uuid");
    localStorage.removeItem("nfts");
    window.location = "/The_Junkiverse/";
  };
  if (!codeExecuted) {
    setCodeExecuted(true);
    logoutModal();
  }
  return (
    <Container className='offers-page'>
      <ViewModal show={showModal} onHide={() => setShowModal(false)}>
        <LogoutModal
          loadingTitle={logoutText}
          loadingDescription={account}
          buttonText='Close'
          onClickButton={() => {
            {
              window.location = "/The_Junkiverse/";
            }
          }}
          logoutButton={() => logout()}
          logoutButtonText={logoutButtonText}
        />
      </ViewModal>
    </Container>
  );
};

export default LogoutPage;
