import { Form } from "react-bootstrap";
import "./styles.scss";


const Checkbox = ({ title, checked, onClick }) => {
    return (
        <div className="custom_checkbox">
            <Form.Check type="checkbox">
                <Form.Check.Input checked={checked} type="checkbox" onClick={onClick}/>
                <Form.Check.Label className="checkbox-label">
                {title}
            </Form.Check.Label>
        </Form.Check>
        </div>
    );
};

export default Checkbox;
