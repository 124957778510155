import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";

const refreshPage = () => {
  window.location = "/The_Junkiverse/refresh";
};

const VideoModal = ({
  firstVideoSource,
  secondVideoSource,
  onClose,
  backdrop,
}) => {
  const [firstVideoEnded, setFirstVideoEnded] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [title, setTitle] = useState("Opening");
  const [description, setDescription] = useState(
    "Your Lootbox has been burnt and your reward is being minted."
  );
  useEffect(() => {
    let ellipsisInterval;

    if (!firstVideoEnded) {
      let ellipsisCount = 0;
      ellipsisInterval = setInterval(() => {
        ellipsisCount = (ellipsisCount + 1) % 4;
        setTitle(`Opening${".".repeat(ellipsisCount)}`);
        setDescription(
          "Your Lootbox has been burnt and your reward is being minted."
        );
      }, 500); // Adjust timing
    } else {
      clearInterval(ellipsisInterval);
      setTitle("Success");
      setDescription(
        "Please check your XRPL Wallet for a push notification to claim your reward."
      );
    }

    return () => clearInterval(ellipsisInterval);
  }, [firstVideoEnded]);

  useEffect(() => {
    const firstVideoElement = document.getElementById("firstVideo");
    const secondVideoElement = document.getElementById("secondVideo");

    const handleFirstVideoEnd = () => {
      setFirstVideoEnded(true);
      secondVideoElement.play();
    };

    const handleSecondVideoEnd = () => {
      setVideoEnded(true);
    };

    firstVideoElement.addEventListener("ended", handleFirstVideoEnd);
    secondVideoElement.addEventListener("ended", handleSecondVideoEnd);

    return () => {
      firstVideoElement.removeEventListener("ended", handleFirstVideoEnd);
      secondVideoElement.removeEventListener("ended", handleSecondVideoEnd);
    };
  }, [secondVideoSource]);

  const handleClose = () => {
    onClose(); // Signal to the parent component to close the modal
  };
  return (
    <div className='video-modal'>
      {!videoEnded ? (
        <>
          <div className='video-container'>
            <div className='close'>
              <h1 className='closeX' onClick={handleClose}>
                X
              </h1>
            </div>
            <video
              id='firstVideo'
              className={`video-element ${
                firstVideoEnded ? "video-hidden" : ""
              }`}
              src={firstVideoSource}
              autoPlay
            />
            <video
              id='secondVideo'
              className={`video-element playsinline ${
                !firstVideoEnded ? "video-hidden" : ""
              }`}
              src={secondVideoSource}
            />
          </div>
          <div className='video-title'>
            <h4 className='vidTitle'>{title}</h4>
            <h5 className='vidDesc'>{description}</h5>
          </div>
        </>
      ) : (
        (window.location.href = "/The_Junkiverse/loot-boxes")
      )}
    </div>
  );
};

export default VideoModal;
