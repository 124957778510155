import CarouselSlider from "../../../../components/CarouselSlider";
import GridCardTwo from "../GridCardTwo";
import "./styles.scss";

const Team = ({ team }) => {
  const isFlexGrid = window.innerWidth <= 1500;

  return (
    <div className="team">
      {isFlexGrid ? (
        <div className="flex-grid">
          {team.map((item, index) => (
            <GridCardTwo
              key={index}
              subtitle={item.name}
              screenName={item.screenName}
              image={item.image}
              description={item.description}
            />
          ))}
        </div>
      ) : (
        <CarouselSlider
          className="inventory-slider"
          slidesToShow={3}
          customSettings={{
            dots: false,
            infinite: false,
            autoplay: false,
            responsive: [
              {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
        >
          {team.map((item, index) => (
            <GridCardTwo
              key={index}
              subtitle={item.name}
              screenName={item.screenName}
              image={item.image}
              description={item.description}
            />
          ))}
        </CarouselSlider>
      )}
    </div>
  );
};


export default Team;
