import React from "react";
import CarouselSlider from "../../../../components/CarouselSlider";
import axios from "axios";
import { useState, useEffect } from "react";

import AnnouncementCard from "./AnnouncementCard";

const Announcements = ({ announcements }) => {
  return (
    <div className='announcements-slider'>
      <CarouselSlider autoplay={true} initialSlide={0}>
        {announcements.map((item, index) => {
          return (
            <AnnouncementCard
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
              onClick={() => window.open(item.link, "_blank")}
              image={item.image}
              button={item.button}
            />
          );
        })}
      </CarouselSlider>
    </div>
  );
};

export default Announcements;
