import { Image } from "react-bootstrap";
import "./styles.scss";

import TwitterIcon from "../../../../assets/images/twitter.png";
import YoutubeIcon from "../../../../assets/images/youtube.png";

const GridCard = ({
  className,
  title,
  subtitle,
  screenName,
  description,
  image,
  twitter,
  youtube,
}) => {
  return (
    <div className={`grid-card-two ${className ?? ""}`}>
      {image ? (
        <div className="image-container">
          <img src={image} className="image-container" />
        </div>
      ) : null}

      {title ? <h1 className="title">{title}</h1> : null}

      <h4 className="subtitle">{subtitle}</h4>
      <h4 className="screenName">{screenName}</h4>
      <p className="description">{description}</p>

      {twitter || youtube ? (
        <div className="socials d-flex align-items-center gap-3">
          {twitter ? (
            <a href={twitter}>
              <Image src={TwitterIcon} alt="twitter_icon" />
            </a>
          ) : null}

          {youtube ? (
            <a href={youtube}>
              <Image src={YoutubeIcon} alt="youtube_icon" />
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default GridCard;
