import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";
import { IpfsImage } from 'react-ipfs-image';

const ClaimOpenModalContent = ({
    loadingTitle = "Opening...",
    loadingDescription,
    openedTitle = "Opened",
    openedDescription,
    onClickButton,
    onClickConfirm,
    onClickClose,
    image,
    claimQR,
    buttonText,
    button,
    link
}) => {
    const [confirmed, setConfirmed] = useState(false);
    const [claimed, setClaimed] = useState(false);
    const [showingImage, setShowingImage] = useState(image);

    if (!confirmed) {
        if (claimQR) {
            setConfirmed(true)
            setShowingImage(claimQR)
        }
    }

    const containsIpfs = (image) => {
    return image.includes("ipfs");
  };

    return (
        <div className="item-open-modal">
            {!confirmed
                ? (
                    <>
                        <h4 className="title">{loadingTitle}</h4>
                        <div className="image-container">
                        {containsIpfs(image) ? (
            <IpfsImage className="image-container" hash={image} />
          ) : (
            <img className="image-container" src={image} />
          )}
                        </div>
                        <p className="description">{loadingDescription}</p>
                        {button
                            ? <div className="claim-buttons"><Button className="primary-button" onClick={onClickConfirm}>Confirm</Button><Button className="primary-button" onClick={onClickClose}>Close</Button></div>
                            : null
                        }
                    </>
                )
                : (
                    <div className="claimed">
                        <div className="image-container"><img className="image-container" src={claimQR} /></div>
                        <h4 className="title">{openedTitle}</h4>
                        {openedDescription
                            ? <p className="description">{openedDescription}</p>
                            : null
                        }
                    <div className="buttons">
                        {button && <Button className="primary-button" onClick={onClickButton}>{buttonText}</Button>}
                        {link ? (<Button className="primary-button" onClick={link}>Open on Mobile</Button>) : null}
                        </div>
                    </div>
                )}
        </div>
    )
}

export default ClaimOpenModalContent;

