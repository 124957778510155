import { Button } from "react-bootstrap";
import "./styles.scss";

const StoreCard = ({
  mintNFT,
  title,
  buttonText,
  image,
  currency,
  value,
  issuer,
}) => {
  return (
    <div className='store-card'>
      <div className='image-container'>
        <a>
          <img className='img-size' src={image} />
        </a>
      </div>
      <div className='store-info'>
        <h3 className='title'>NFT: {title}</h3>
      </div>
      <div className='store-info'>
        <h3 className='price'>
          Price: {value} {currency}
        </h3>
      </div>
      <div className='button-div'>
        <button className='mint-button' onClick={mintNFT}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default StoreCard;

//style={{ cursor: "pointer" }}
