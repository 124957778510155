import { Image } from "react-bootstrap";
import "./styles.scss";

import AnnouncementIcon from "../../../../assets/images/announcement.png";
import CarouselSlider from "../../../../components/CarouselSlider";
import ActiveClaimCard from "../ActiveClaimCard";
import DroidProfile from "../../../../assets/images/Droid-Profile.png";

const ActiveClaims = ({ onClaim, claims, setActive }) => {
  const handleClaimClick = (claim) => {
    setActive(claim);
  };
  return (
    <div className='active-claims container-lg'>
      <div className='header'>
        <Image src={AnnouncementIcon} alt='Icon' />
        <h6 className='title'>Active claims</h6>
      </div>

      <CarouselSlider autoplay={false} initialSlide={1}>
        {claims.map((item, index) => {
          return (
            <ActiveClaimCard
              key={index}
              active={item.active}
              date={item.date}
              title={item.title}
              description={item.description}
              onClaim={() => {
                onClaim();
                handleClaimClick(item);
              }}
              image={item.image}
            />
          );
        })}
      </CarouselSlider>
    </div>
  );
};

export default ActiveClaims;
